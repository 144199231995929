export const getNypRoutes = () => {
  return [
    {
      meta: {
        title: 'Avatars Screen'
      },
      path: '/nyp/avatars',
      name: 'nypAvatarsScreen',
      component: () => import('./AvatarsScreen.vue')
    }
  ]
}
