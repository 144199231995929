<template>
  <div>
    <modal-box
      :is-active="isModalActive"
      :trash-object-name="trashObjectName"
      @confirm="trashConfirm"
      @cancel="trashCancel"
    />
    <b-table
      :checked-rows.sync="checkedRows"
      :checkable="checkable"
      :loading="isLoading"
      :paginated="paginated"
      :per-page="perPage"
      :striped="true"
      :hoverable="true"
      :data="formattedAdventures"
    >
      <b-table-column label="Title" field="title" sortable v-slot="props">
        {{ props.row.details.adventure_details.name }}
      </b-table-column>
      <b-table-column label="Last Updated" field="updatedOn" sortable v-slot="props">
        {{ props.row.updatedOn ? props.row.updatedOn.toLocaleString('en-US', { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }) : null }}
      </b-table-column>
      <b-table-column custom-key="actions" cell-class="is-actions-cell" v-slot="props">
        <div class="buttons is-right">
          <router-link :to="{name:'managePlayTour', params: {adventureId: props.row.id}}" class="button is-small is-primary">
            Manage
            <i class="fas fa-chevron-right ml-2"></i>
          </router-link>
        </div>
      </b-table-column>

      <section slot="empty" class="section">
        <div class="content has-text-grey has-text-centered">
          <template v-if="isLoading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large" />
            </p>
            <p>Fetching data...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="emoticon-sad" size="is-large" />
            </p>
            <p>Nothing's here&hellip;</p>
          </template>
        </div>
      </section>
    </b-table>
  </div>
</template>

<script>
import ModalBox from '@/components/ModalBox'

export default {
  name: 'AdventuresTable',
  components: { ModalBox },
  props: {
    checkable: {
      type: Boolean,
      default: false
    },
    filterText: {
      type: String,
      default: null
    },
    perPage: {
      type: Number,
      default: 15
    }
  },
  data () {
    return {
      isModalActive: false,
      trashObject: null,
      isLoading: false,
      paginated: true,
      checkedRows: []
    }
  },
  computed: {
    adventures () {
      const adventures = this.$store.state.adventures.filter(adventure => {
        if (
          !this.$store.state.organisation.adventureVisibilities ||
          !this.$store.state.organisation.adventureVisibilities[adventure.id]
        ) return true
        if (
          this.$store.state.profile.isRootAccount ||
          this.$store.state.organisation.adventureVisibilities[adventure.id].includes(this.$store.state.profile.id)
        ) {
          return true
        }
        return false
      })
      if (this.filterText) {
        const sanitizedFilterText = this.filterText.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()
        return adventures.filter(adventure =>
          adventure.details.adventure_details.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase().includes(sanitizedFilterText)
        )
      }
      return adventures
    },
    formattedAdventures () {
      return this.adventures
        .map(adventure => {
          return {
            ...adventure,
            title: adventure.details.adventure_details.name,
            updatedOn: adventure.updatedOn ? adventure.updatedOn.toDate() : null
          }
        })
        .sort((a, b) => {
          if (a.updatedOn && b.updatedOn) {
            return new Date(b.updatedOn) - new Date(a.updatedOn)
          } else if (a.updatedOn) {
            return -1
          } else if (b.updatedOn) {
            return 1
          } else {
            return 0
          }
        })
    },
    trashObjectName () {
      if (this.trashObject) {
        return this.trashObject.name
      }

      return null
    }
  },
  methods: {
    trashModal (trashObject) {
      this.trashObject = trashObject
      this.isModalActive = true
    },
    trashConfirm () {
      this.isModalActive = false
      this.$buefy.snackbar.open({
        message: 'Confirmed',
        queue: false
      })
    },
    trashCancel () {
      this.isModalActive = false
    }
  }
}
</script>
